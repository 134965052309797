const appConfig = JSON.parse(document.getElementById('root').dataset.config);
const Config = {
    'csrf_token': '',
    'path': {
        'panel_index': '',
        'acl_rules': '',
        'logout': '',
        'login': {
            'login_page': '',
            'perform_login': '',
            'perform_2fa': '',
            'setup_2fa': '',
            'setup_password': '',
            'secret_2fa': '',
            'next_step': '',
        },
        'user': {
            'list': '',
            'profile': '',
            'change_password': '',
            'load_secret_code': '',
            'change_2fa': '',
        },
        'user_activity_log': {
            'list': '',
        },
        'customer': {
            'list': '',
            'enable': '',
            'disable': '',
            'delete': '',
            'limit': '',
            'reset_second_factor': '',
            'get_login_url': '',
        },
        'organization': {
            'list': '',
            'limit': '',
        },
    },
    ...appConfig
};

export default Config;